<template>
  <div class="innerbox">
    <econ-whereyear  v-if="!nodata"  :rangeYear="rangeYear" :curAreaId="curAreaId" :citylist="citylist" @cid-rangeyear="cidRangeyear"
      @change-areaid="changeAreaid"></econ-whereyear>
    <div class="scrollbox">
      <div v-if="nodata">
        <el-result icon="info" title="暂无数据" subTitle="当前城市数据不存在...">
        </el-result>
      </div>
      <div v-else class="report">
        <div class="addtable">
          <table-1 :tableData="addTableData" :cateid="cateid[0]" :hideRight="true" @download="downloadExcel"></table-1>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EconWhereyear from "../../components/cityreserch/EconWhereyear.vue";
import Table1 from "@/components/tables/Table1.vue";
import TimeSource from "../../components/cityreserch/TimeSource.vue";
import {
  cityYearByCateid,
  dataByCateid,
  rangeYearByCateid,
  addTableDataByCateid,
  downloadExcelByCateid
} from "../../api/econ";
export default {
  name: "VueElementTupaiGdp",
  components: { EconWhereyear, TimeSource, Table1 },
  data() {
    return {
      cateid: [11],
      addTableData: {}, //附加表数据  table1 组建用的props
      nodata: false,
      rangeYear: [],
      curAreaId: 0,
      citylist: [],
      tableData: {},
      valueObj: null,
      download_start_year: null,
      download_end_year: null
    };
  },

  mounted() {
    this.getWhere();
  },
  computed: {
    shi: {
      get() {
        return this.$store.state.shi;
      },
      set(shi) {
        this.$store.dispatch("changeShi", shi);
      },
    },
  },
  watch: {
    shi() {
      //   this.currentArea = this.cityList[0].id
      this.getWhere();
    },
  },
  methods: {
    //更改当前的区id
    changeAreaid(id) {
      console.log(this.rangeYear);
      this.getCurrentRange({ city_plate_id: id, cateid: this.cateid[0] });
    },

    getCurrentRange(params) {
      rangeYearByCateid(params).then((res) => {

        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.rangeYear = res.data.rangeYear;
      });
    },

    //下载excel
    downloadExcel() {
      const params = {
        city_plate_id: this.curAreaId,
        year: this.download_end_year || this.rangeYear[1],
        cateid: this.cateid[0]
      };

      downloadExcelByCateid(params).then((res) => {
        if (res.code === 1001) {
          this.$message("此文档不存在...");
        } else {
          this.download = res.data;
          window.location.href = this.download.fileUrl;
        }
      });
    },

    //子组建返回的事件
    cidRangeyear(obj) {
      this.curAreaId = obj.areaId;
      const params = {
        city_plate_id: obj.areaId,
        year: obj.endyear,
        cateid: this.cateid[0]
      };
      this.download_start_year = obj.startyear
      this.download_end_year = obj.endyear

      this.getData(params);
    },


    getWhere() {
      //参数 为通用的参数，当前城市id 和当前分类id
      const params = { city_plate_id: this.$store.state.shi, cateid: this.cateid[0] };

      cityYearByCateid(params).then((res) => {

        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        } else {
          this.nodata = false;
        }
        this.citylist = res.data.citylist;
        this.rangeYear = res.data.rangeYear;
        res.data.citylist.every((item) => {
          if (!item.disabled) {
            this.curAreaId = item.id;
            return false;
          }
        });





        if (this.rangeYear.length !== 0) {
          const params = {
            city_plate_id: this.curAreaId,
            // city_plate_id:12,
            year: this.rangeYear[1],
            // year:2015,
            cateid: this.cateid[0]
          };

          this.nodata = false
          this.getData(params);
        } else {
          this.tableData = []
          this.nodata = true
          this.$message('暂无数据')
        }







      });
    },

    getData(params) {

      console.log(params)

      addTableDataByCateid(params).then((res) => {
        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }

        const addData = res.data;
        this.addTableData = addData;
      });
    }

  },
};
</script>

<style lang="scss" scoped>
.innerbox {
  width: 100%;
  height: auto;
}

.scrollbox {
  height: calc(100vh - 260px);
  overflow: scroll;
  width: 96%;
  margin: 0 auto;
}

.report {
  //  padding-top:140px;
  //  margin-top:140px;

  //  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .tablebox {
    width: 100%;

    .title {
      margin-top: 15px;
      font-size: 18px;
      font-weight: 400;
    }

    .downbox {
      text-align: right;
      position: relative;
      top: -20px;
      left: -40px;
    }
  }

  .piebox {
    width: 40%;
    display: flex;
    flex-direction: column;

    .pie {
      min-height: 260px;
    }
  }
}

::v-deep .cell3,
::v-deep .cell5,
::v-deep .cell9 {
  border-right: solid 2px #efefef;
}

.addtable {}
</style>